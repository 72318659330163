.back-button {
    display: flex;
    align-items: center;
    font-size: 2.2em;
}

.back-button .button button {
    font-size: .65em;
    letter-spacing: -.05em;
    background: none;
    border: none;
}

.back-button,
.back-button .button button {
    color: var(--accent-color);
}

.back-button svg {
    margin-right: .5em;
}