.filters {
    margin-top: 6vh;
    opacity: 0;
    animation: fadeIn 300ms ease-in-out forwards;
}

.filters > ul {
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
    margin-bottom: 8vh;
}

.filters > ul > li {
    list-style: none;
    margin: 0 6vw;
    font-size: 2em;
    letter-spacing: -.05em;
    position: relative;
    cursor: pointer;
}

.filters > ul > li.active::after {
    content: "";
    position: absolute;
    bottom: -.6rem;
    left: 0;
    right: 0;
    height: .32rem;
    background-color: var(--accent-color);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}