.filter-list > ul {
    column-count: 4;
    column-gap: 3vw;
    column-width: 17rem;
    margin: 0;
    padding: 0 5vw;
}

@media screen and (max-aspect-ratio: 2/3) {
    .filter-list > ul {
        column-count: 1;
        column-gap: 0;
        column-width: auto;
        padding: 0 15vw;
    }
}