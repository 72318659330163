.recipe-card {
    position: relative;
    background-color: white;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-radius: 2vw;
    box-shadow: 3px 3px 11px 2px #00000017;
    font-size: .8vw;
    transition: transform 200ms ease-in-out;
}

.recipe-card:not(.loading):hover {
    transform: scale(1.05);
}

.recipe-card .card-img,
.recipe-card .card-infos {
    position: absolute;
    left: 0;
    right: 0;
}

.recipe-card .card-img {
    top: 0;
    bottom: 30%;
    border-radius: 2vw 2vw 0 0;
}

.recipe-card .card-img img {
    border-radius: 2vw 2vw 0 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.recipe-card .card-infos {
    top: 70%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10%;
}

.recipe-card .card-infos p {
    margin: 0;
    font-size: 1.45em;
    letter-spacing: -.05em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    width: 100%;
}

.recipe-card .card-infos p:nth-child(2) {
    color: #d0d0d0;
    display: flex;
    align-items: center;
    margin-top: 4%;
}

.recipe-card .card-infos p:nth-child(2) svg {
    font-size: 1.2em;
    margin-right: 4%;
}

.recipe-card.loading .card-img {
    background-image: linear-gradient(90deg, #e0e0e0 0%, #ffffff 100%);
    background-size: 200% 100%;
    animation: loadingCard 2s ease-in-out infinite;
}

@media screen and (max-aspect-ratio: 5/4) {
    .recipe-card {
        font-size: 1.3vw;
    }
}

@media screen and (max-aspect-ratio: 1/1) {
    .recipe-card {
        font-size: 2vw;
    }
}

@media screen and (max-aspect-ratio: 3/4) {
    .recipe-card {
        font-size: 2.5vw;
    }
}

@keyframes loadingCard {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}