.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 15vh;
    display: flex;
    align-items: center;
    background-color: #ffffff00;
    padding: 0 9vw;
    z-index: 2;
    transition: background-color 200ms ease-in-out;
}

.navbar.opaque {
    background-color: #ffffffff;
}

.navbar .logo {
    height: 6.3vh;
}

.navbar .navbar-entries,
.navbar .navbar-mobile-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navbar .navbar-entries > a {
    margin-left: 5vw;
}

.navbar .navbar-mobile-button {
    display: none;
}

.navbar .navbar-mobile-button svg {
    color: var(--black);
    font-size: 2rem;
    cursor: pointer;
}

@media screen and (max-aspect-ratio: 6/5) {
    .navbar {
        height: 10vh;
    }

    .navbar .logo {
        height: 5vh;
    }

    .navbar .navbar-entries:not(.open) {
        display: none;
    }

    .navbar .navbar-entries.open {
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 15vh;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        z-index: -1;
        padding-bottom: 5vh;
    }

    .navbar .navbar-entries > a {
        margin-left: 0;
    }

    .navbar .navbar-mobile-button {
        display: flex;
    }
}