.preference-choice {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10vh;
    z-index: 1;
    font-size: 1rem;
}

.preference-choice h1 {
    margin-bottom: 10vh;
    font-size: 3em;
    letter-spacing: -.05em;
}

.preference-choice .wrapper {
    display: flex;
    align-items: center;
    max-width: 70vw;
    position: relative;
    overflow-x: hidden;
    padding: 2vh 0;
}

.preference-choice .wrapper .entries {
    position: absolute;
    display: flex;
    align-items: center;
    position: relative;
    left: 0;
    transition: left 500ms ease-in-out;
}

.preference-choice .wrapper div.left-btn,
.preference-choice .wrapper div.right-btn {
    position: absolute;
    z-index: 1;
    height: 100%;
    display: flex;
    align-items: center;
}

.preference-choice .wrapper div.left-btn {
    left: 0;
    background-image: linear-gradient(90deg, #2d2d2d1c 0%, #2d2d2d00 50%);
}

.preference-choice .wrapper div.left-btn .button {
    margin-left: 1vw;
}

.preference-choice .wrapper div.right-btn {
    right: 0;
    background-image: linear-gradient(90deg, #2d2d2d00 50%, #2d2d2d1c 100%);
}

.preference-choice .wrapper div.right-btn .button {
    margin-right: 1vw;
}

.preference-choice .wrapper .button button {
    color: var(--black);
    padding: 0;
    width: 2vw;
    height: 2vw;
    background-color: white;
    border: none;
    box-shadow: 3px 3px 11px 2px #00000017;
    transition: transform 200ms ease-in-out;
}

.preference-choice .wrapper .button button:hover {
    transform: scale(1.1);
}

@media screen and (max-aspect-ratio: 3/2) {
    .preference-choice {
        font-size: 2vw;
    }

    .preference-choice .wrapper {
        max-width: 85vw;
    }

    .preference-choice .wrapper .button button {
        width: 3vw;
        height: 3vw;
    }
}

@media screen and (max-aspect-ratio: 2/3) {
    .preference-choice .wrapper {
        max-width: 100vw;
    }

    .preference-choice .wrapper .button button {
        width: 6vw;
        height: 6vw;
    }
}