.navbar-entry {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    letter-spacing: -.08rem;
    transition: transform 150ms ease-in-out;
}

.navbar-entry:hover {
    transform: scale(1.05);
}

@media screen and (min-aspect-ratio: 6/5) {
    .navbar-entry.designed {
        color: white;
        background-color: var(--accent-color);
        padding: 1.3vh 1.5vw;
        border-radius: 100px;
    }
}

@media screen and (max-aspect-ratio: 6/5) {
    .navbar-entry {
        margin: 1.5vh 0;
        font-size: 1.5rem;
    }
}