.nutrition-stat {
    width: 9.5em;
    height: 9.5em;
    border-radius: 9em;
    margin: 0 1.4vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.nutrition-stat:first-of-type {
    margin-left: 0;
}

.nutrition-stat:last-of-type {
    margin-right: 0;
}

.nutrition-stat p {
    margin: 0;
    font-size: 1.6em;
}

@media screen and (max-aspect-ratio: 5/4) {
    .nutrition-stat {
        width: 12em;
        height: 12em;
    }
}

@media screen and (max-aspect-ratio: 3/4) {
    .nutrition-stat {
        margin: 0;
    }
}