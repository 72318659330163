#conf-page {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/preferences/background.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
}

#conf-page::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.8;
    z-index: 0;
}

#conf-page .logo {
    z-index: 1;
    width: 20vw;
    margin-bottom: 5vh;
}

#conf-page .buttons {
    display: flex;
    justify-content: center;
    z-index: 1;
}

#conf-page .buttons .button {
    margin: 0 2vw;
}

#conf-page .buttons .button button {
    font-size: 1.2rem;
}

@media screen and (max-aspect-ratio: 1/1) {
    #conf-page .logo {
        width: 40vw;
    }
}