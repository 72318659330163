.recipe-search {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vh 0;
    font-size: 1rem;
}

.recipe-search .search-input {
    display: flex;
    justify-content: center;
    align-items: center;
}

.recipe-search .search-input .input {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .9em 2.5em;
    border-radius: 100px;
    box-shadow: 3px 3px 11px 2px #00000017;
    margin-right: 3vw;
}

.recipe-search .search-input .input svg {
    color: #b3b5b6;
    font-size: 1.7em;
    margin-right: 1.5vw;
}

.recipe-search .search-input .input input {
    background: none;
    border: none;
    font-size: 1.7em;
    width: 27vw;
    font-family: "Century Gothic Bold", sans-serif;
    outline: none;
}

.recipe-search .search-input .button button {
    background: none;
    border: none;
}

.recipe-search .search-input .button svg {
    color: #b3b5b6;
    font-size: 2.3em;
    transition: color 150ms ease-in-out;
}

.recipe-search .search-input .button.active svg {
    color: var(--accent-color);
}

@media screen and (max-aspect-ratio: 1/1) {
    .recipe-search {
        font-size: 1.5vw;
    }

    .recipe-search .search-input .input input {
        width: 60vw;
    }
}

@media screen and (max-aspect-ratio: 2/3) {
    .recipe-search {
        font-size: 2vw;
    }
}