.step-list-entry {
    margin-left: 1vw;
    margin-bottom: 2.2em;
}

.step-list-entry p {
    font-size: 1.6em;
    margin: 0;
}

.step-list-entry p:first-of-type {
    margin-bottom: .8em;
}

.step-list-entry p:first-of-type span {
    color: var(--accent-color);
}

.step-list-entry p:last-of-type {
    font-family: "Century Gothic", sans-serif;
}