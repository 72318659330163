.filter-list-entry {
    list-style: none;
    font-family: "Century Gothic", sans-serif;
    font-size: 1.7em;
    letter-spacing: -.05em;
    cursor: pointer;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
}

.filter-list-entry svg {
    color: white;
    font-size: .9em;
    margin-right: 1vw;
}

.filter-list-entry.checked {
    color: var(--accent-color);
    font-family: "Century Gothic Bold", sans-serif;
}

.filter-list-entry.checked svg {
    color: var(--accent-color);
}