#no-route-match {
    margin-top: 15vh;
    padding: 0 20vw;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#no-route-match .code {
    display: flex;
    justify-content: center;
    align-items: center;
}

#no-route-match .code p {
    font-size: 11vw;
    margin: 0;
}

#no-route-match .code img {
    height: 8vw;
}

#no-route-match > p {
    font-size: 5rem;
    margin: 0;
    margin-left: 5vw;
}