#preferences {
    margin-top: 15vh;
    font-size: 1rem
}

#preferences h2 {
    font-size: 2.5em;
    letter-spacing: -.05em;
    padding: 0 10vw;
    margin-bottom: 5vh;
}

#preferences .preference-choice h1 {
    font-size: 2rem;
    margin-bottom: 3vh;
    text-align: center;
}

#preferences .preference-choice .preference-entry {
    width: 6vw;
    height: 6vw;
    padding: .5vw;
}

@media screen and (max-aspect-ratio: 3/2) {
    #preferences .preference-choice .preference-entry {
        width: 9vw;
        height: 9vw;
    }

    #preferences .preference-choice {
        font-size: 1.5vw;
    }

    #preferences .preference-choice .wrapper {
        max-width: 85vw;
    }

    #preferences .preference-choice .wrapper .button button {
        width: 3vw;
        height: 3vw;
    }
}

@media screen and (max-aspect-ratio: 1/1) {
    #preferences .preference-choice .preference-entry {
        width: 12vw;
        height: 12vw;
    }
}

 @media screen and (max-aspect-ratio: 2/3) {
    #preferences .preference-choice .preference-entry {
        width: 18vw;
        height: 18vw;
    }

    #preferences .preference-choice .preference-entry p {
        font-size: 1.5em;
    }

    #preferences .preference-choice .wrapper {
        max-width: 95vw;
    }

    #preferences .preference-choice .wrapper .button button {
        width: 6vw;
        height: 6vw;
    }
}