#main-page {
    position: relative;
}

#main-page .landing-picture {
    position: relative;
    font-size: 1vw;
}

#main-page .landing-picture::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(#ffffff 0%, #ffffff00 20%, #ffffff00 80%, #ffffff 100%);
}

#main-page .landing-picture img {
    width: 100%;
}

#main-page .landing-picture h1 {
    position: absolute;
    bottom: 17%;
    left: 14%;
    margin: 0;
    width: 50%;
    font-size: 8.6em;
    letter-spacing: -.05em;
    line-height: .93em;
    z-index: 1;
}

@media screen and (max-aspect-ratio: 1/1) {
    #main-page .landing-picture {
        font-size: 1.2vw;
        height: 60vh;
    }

    #main-page .landing-picture img {
        height: 100%;
        object-fit: cover;
    }
}

@media screen and (max-aspect-ratio: 4/5) {
    #main-page .landing-picture {
        font-size: 1.5vw;
        height: 50vh;
    }

    #main-page .landing-picture h1 {
        width: 70%;
    }
}