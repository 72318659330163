.recipe-grid {
    padding: 0 10vw;
    margin-bottom: 10vh;
    font-size: 1rem;
}

.recipe-grid > h2 {
    font-size: 2.5em;
    letter-spacing: -.05em;
    margin: 0;
    margin-bottom: 6vh;
}

.recipe-grid > div:not(.empty-comment) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2vw;
    row-gap: 2vw;
}

.recipe-grid .empty-comment {
    display: flex;
    justify-content: center;
}

.recipe-grid .empty-comment p {
    font-size: 1.5rem;
    letter-spacing: -.1rem;
    color: #d3d3d3;
}

@media screen and (max-aspect-ratio: 5/4) {
    .recipe-grid > div:not(.empty-comment) {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 3.5vw;
        row-gap: 3.5vw;
    }
}

@media screen and (max-aspect-ratio: 1/1) {
    .recipe-grid {
        font-size: 1.5vw;
    }

    .recipe-grid > div:not(.empty-comment) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 5vw;
        row-gap: 5vw;
    }
}

@media screen and (max-aspect-ratio: 3/4) {
    .recipe-grid {
        font-size: 2vw;
        padding: 0 5vw;
    }
}