.nutrition-widget {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 7em;
}

@media screen and (max-aspect-ratio: 3/4) {
    .nutrition-widget {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 3em;
        row-gap: 3em;
    }

    .nutrition-widget .nutrition-stat:nth-of-type(1),
    .nutrition-widget .nutrition-stat:nth-of-type(3) {
        justify-self: end;
    }
}