.preference-entry {
    width: 7vw;
    height: 7vw;
    margin: 0 1vw;
    padding: 1vw;
    border-radius: 20vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    position: relative;
    transition: transform 200ms ease-in-out;
}

.preference-entry:hover {
    transform: scaleX(1.05) scaleY(1.05);
}

.preference-entry img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    border-radius: 20vw;
    opacity: 1;
    transition: opacity 200ms ease-in-out;
}

.preference-entry p {
    color: #ffffff;
    text-align: center;
}

.preference-entry p::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 20vw;
    background-color: #00000044;
    transition: background-color 200ms ease-in-out;
}

.preference-entry.active p::before {
    background-color: var(--accent-color);
}

.preference-entry.active img {
    opacity: 0;
}

@media screen and (max-aspect-ratio: 3/2) {
    .preference-entry {
        width: 10vw;
        height: 10vw;
        margin: 0 1vw;
        padding: 1vw;
    }
}

@media screen and (max-aspect-ratio: 2/3) {
    .preference-entry {
        width: 20vw;
        height: 20vw;
    }

    .preference-entry p {
        font-size: 1.5em;
    }
}