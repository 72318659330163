#recipe-page {
    margin-top: 15vh;
    padding: 6vh 18vw 0;
    font-size: 1rem;
}

#recipe-page .recipe-presentation {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8vw;
    margin-top: 5vh;
    margin-bottom: 7em;
}

#recipe-page .recipe-presentation .recipe-thumbnail {
    width: 100%;
    position: relative;
    height: 0;
    padding-bottom: 100%;
}

#recipe-page .recipe-presentation .recipe-thumbnail > div {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

#recipe-page .recipe-presentation .recipe-thumbnail > div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2vw;
}

#recipe-page .recipe-presentation .recipe-infos {
    display: flex;
    flex-direction: column;
}

#recipe-page .recipe-presentation .recipe-infos h1 {
    font-size: 3em;
    letter-spacing: -0.05em;
    margin: 0;
    margin-bottom: .8em;
}

#recipe-page .recipe-presentation .recipe-infos > p {
    font-size: 1.5em;
    margin: 0;
    color: #c0c0c0;
    margin-bottom: 3em;
}

#recipe-page .recipe-presentation .recipe-infos > div:nth-of-type(1) {
    display: grid;
    grid-template-columns: 5.5em auto;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 2em;
    align-items: center;
    margin-bottom: 3.5em;
}

#recipe-page .recipe-presentation .recipe-infos > div:nth-of-type(1) svg {
    font-size: 3em;
    color: var(--black);
}

#recipe-page .recipe-presentation .recipe-infos > div:nth-of-type(1) p {
    font-size: 2.3em;
    letter-spacing: -.05em;
    margin: 0;
}

#recipe-page .recipe-presentation .recipe-infos .fav-button {
    align-self: center;
}

#recipe-page .recipe-presentation .recipe-infos .fav-button button {
    font-size: 1.3em;
}

@media screen and (max-aspect-ratio: 3/2) {
    #recipe-page {
        padding: 6vh 10vw 0;
    }
}

@media screen and (max-aspect-ratio: 5/4) {
    #recipe-page {
        font-size: 1vw;
    }
}

@media screen and (max-aspect-ratio: 6/5) {
    #recipe-page {
        margin-top: 10vh;
    }
}

@media screen and (max-aspect-ratio: 1/1) {
    #recipe-page {
        font-size: 1.3vw;
    }

    #recipe-page .recipe-presentation .recipe-infos > p {
        font-size: 2em;
    }
}

@media screen and (max-aspect-ratio: 3/4) {
    #recipe-page {
        padding: 6vh 15vw 0;
        font-size: 2vw;
    }

    #recipe-page .recipe-presentation {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);
    }

    #recipe-page .recipe-presentation .recipe-thumbnail {
        grid-row: 1/1;
        margin-bottom: 5em;
    }
}