body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --accent-color: #ff2657;
  --black: #2f292a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, p, a, li, button {
  color: var(--black);
  font-family: 'Century Gothic Bold', sans-serif;
}

a {
  text-decoration: none;
}

::placeholder {
  font-family: 'Century Gothic Bold', sans-serif;
  color: #d3d3d3;
  opacity: 1;
}

:-ms-input-placeholder {
  font-family: 'Century Gothic Bold', sans-serif;
  color: #d3d3d3;
}

::-ms-input-placeholder {
  font-family: 'Century Gothic Bold', sans-serif;
  color: #d3d3d3;
}

@font-face {
  font-family: "Century Gothic";
  src: url("./assets/fonts/GOTHIC.TTF") format("truetype");
}

@font-face {
  font-family: "Century Gothic Bold";
  src: url("./assets/fonts/GOTHICB.TTF") format("truetype");
}