.button button {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    background-color: var(--accent-color);
    padding: .7em 1.5em;
    border-radius: 100px;
    border: 3px solid var(--accent-color);
}

.button.outline button {
    color: var(--accent-color);
    background: none;
}