.ingredient-list-entry {
    margin-left: 1vw;
    margin-bottom: 2.2em;
}

.ingredient-list-entry p {
    display: flex;
    align-items: center;
    line-height: 1em;
    font-size: 1.6em;
}

.ingredient-list-entry p::before {
    content: '\00b7';
    font-size: 3em;
    color: var(--accent-color);
    margin-right: .5em;
}