.loading-screen {
    margin-top: 15vh;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading-screen img {
    width: 10vw;
    animation: loadingSpin 5s linear infinite;
}

.loading-screen p {
    font-size: 2.5rem;
    margin: 0;
    margin-top: 1vh;
}

@keyframes loadingSpin {
    0% {
        transform: rotateZ(0deg);
    }

    100% {
        transform: rotateZ(360deg);
    }
}